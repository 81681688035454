import Strapi from './Strapi';
import qs from 'qs';

const collection = 'companies';

const getCompanies = (query) => Strapi.get(`${collection}?${qs.stringify(query)}`);
const getCompaniesCount = (query) => Strapi.get(`${collection}/count?${qs.stringify(query)}`);
const getCompanyById = (id) => Strapi.get(`${collection}/${id}`);
const createCompany = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editCompany = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const delCompany = (id) => Strapi.delete(`${collection}/${id}`);
const getReport = (companyId, year, parent, type) => 
  Strapi.get(`${collection}/${companyId}/report/${year}?parent=${parent}&type=${type}`);
const getDashboardData = (id, year, noCache = false) =>
  Strapi.get(`${collection}/${id}/dashboard/${year}?no_cache=${noCache ? 'true' : 'false'}`);
const deleteDashboardCache = (id) => Strapi.delete(`${collection}/${id}/dashboard`);

export {
  getCompanies,
  getCompaniesCount,
  getCompanyById,
  createCompany,
  delCompany,
  editCompany,
  getReport,
  getDashboardData,
  deleteDashboardCache,
};
